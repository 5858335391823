import React from "react"

import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import ServiceCards from "../../components/serviceCards"

import Layout from "../../components/layout"
import Head from "../../components/head"

const useStyles = makeStyles(theme => ({
  heroContent: {
    padding: theme.spacing(15, 0, 12),

    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(9, 0, 4),
    },
  },
  heroGridContainer: {
    display: "grid",
    gridGap: theme.spacing(6),
    gridTemplateRows: "1fr",
    gridTemplateColumns: "1fr 1fr",
    justifyContent: "center",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
    },
  },
  heroImage: {
    width: "100%",
  },
  heroGridItemImage: {
    textAlign: "center",
  },
  bottomMargin: {
    marginBottom: theme.spacing(6),
  },
  logos: {
    width: "80px",
  },
  iconWrap: {
    display: "flex",
    height: "100%",
    alignItems: "center",
  },
  contactDetail: {
    margin: theme.spacing(7, 0),
  },
}))

const Services = () => {
  const classes = useStyles()

  return (
    <Layout>
      <Head title="Services" />
      <main>
        <div className={classes.heroContent}>
          <Container maxWidth="lg">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              Our Legal Services
            </Typography>
            <Typography
              variant="h5"
              align="center"
              color="textSecondary"
              paragraph
            >
              Janice Vu & Associates Pty Ltd provides the following legal
              services in
            </Typography>
          </Container>

          <ServiceCards />
        </div>
      </main>
    </Layout>
  )
}

export default Services
