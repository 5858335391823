import React from "react"
import { Link as GaLink } from "gatsby"

import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import CardActions from "@material-ui/core/CardActions"
import CardActionArea from "@material-ui/core/CardActionArea"
import Button from "@material-ui/core/Button"
import CardMedia from "@material-ui/core/CardMedia"

import houseSearching from "../assets/house_searching.svg"
import contract from "../assets/contract.svg"
import playTime from "../assets/play_time.svg"
import journey from "../assets/journey.svg"
import files from "../assets/files.svg"
import syncFiles from "../assets/sync_files.svg"

const WHAT_WE_DO = [
  {
    title: "Conveyancing and Property Matters",
    subtitle:
      "Purchase and sale of residential and commercial property and off the plan property purchases.",
    media: houseSearching,
    slug: "conveyancing",
  },
  {
    title: "Commercial Matters",
    subtitle:
      "Leases, purchase and sale of businesses, drafting of contractual agreements e.g. loan agreements, partnerships, and general powers of attorney",
    media: contract,
    slug: "commercial-matters",
  },
  {
    title: "Family Law Matters",
    subtitle:
      "Divorce, financial agreements, parenting agreements, pre-nuptial agreements.",
    media: playTime,
    slug: "family-law",
  },

  {
    title: "Immigration Law",
    subtitle:
      "Specialising in all matters regarding immigration law including review to the Administrative Appeals Tribunal, to the Federal Circuit Court, and also Ministerial appeals. The principal solicitor, Janice Vu is an accredited specialist in immigration law.",
    media: journey,
    slug: "immigration-law",
  },
  {
    title: "Notary Public",
    subtitle:
      "Providing Notary Public documentation to certify documents and identify parties for Australian and overseas countries requirements.",
    media: files,
    slug: "notary-public",
  },
  {
    title: "Wills and Probate",
    subtitle:
      "Drafting of wills, probate and letters of administration matters; enduring guardianship and enduring powers of attorney.",
    media: syncFiles,
    slug: "wills-probate",
  },
]

const useStyles = makeStyles(theme => ({
  item: {
    display: "flex",
    flexFlow: "column",
    justifyContent: "space-between",
  },

  cardGrid: {
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(7),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },

  cardContent: {
    flexGrow: 1,
    height: "100%",
  },
  girdContainer: {
    display: "grid",
    gridGap: theme.spacing(4),
    gridTemplateColumns: "repeat(auto-fill, minmax(320px, 1fr))",
    marginTop: theme.spacing(3),
  },
  cardGridContainer: {
    display: "grid",
    gridGap: theme.spacing(5),
    gridTemplateRows: "240px 1fr",
    justifyContent: "center",
  },
  cardMedia: {
    objectFit: "contain",
    padding: theme.spacing(5),
  },
  mediaWrap: {
    display: "flex",
    alignItems: "center",
  },
}))

const ServiceCards = () => {
  const classes = useStyles()

  return (
    <Container className={classes.cardGrid} maxWidth="lg">
      <div className={classes.girdContainer}>
        {WHAT_WE_DO.map(item => (
          <Card className={classes.item} key={item.title}>
            <CardActionArea
              component={React.forwardRef((props, ref) => (
                <GaLink innerRef={ref} {...props} />
              ))}
              to={`/services/${item.slug}`}
            >
              <CardMedia
                component="img"
                alt={item.title}
                height="300"
                image={item.media}
                title={item.title}
                className={classes.cardMedia}
              />

              <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant="h5" component="h3">
                  {item.title}
                </Typography>
                <Typography
                  gutterBottom
                  variant="body1"
                  component="p"
                  color="textSecondary"
                >
                  {item.subtitle}
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button
                size="small"
                component={React.forwardRef((props, ref) => (
                  <GaLink innerRef={ref} {...props} />
                ))}
                color="inherit"
                to={`/services/${item.slug}`}
              >
                Learn More
              </Button>
            </CardActions>
          </Card>
        ))}
      </div>
    </Container>
  )
}

export default ServiceCards
